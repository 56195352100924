import React from 'react'
import { Box } from '../ui'
import EventPreviewSkeleton from './EventPreviewSkeleton'

export default function EventListSkeleton() {
  return (
    <Box
      mx={[0, -2]}
      flexWrap="wrap"
    >
      <Box
        pb={4}
        width={1}
        px={[0, 2]}
      >
        <EventPreviewSkeleton />
      </Box>
      <Box
        pb={3}
        width={1}
        px={[0, 2]}
      >
        <EventPreviewSkeleton />
      </Box>
      <Box
        pb={4}
        width={1}
        px={[0, 2]}
      >
        <EventPreviewSkeleton />
      </Box>
      <Box
        pb={4}
        width={1}
        px={[0, 2]}
      >
        <EventPreviewSkeleton />
      </Box>
      <Box
        pb={4}
        width={1}
        px={[0, 2]}
      >
        <EventPreviewSkeleton />
      </Box>
    </Box>
  )
}
