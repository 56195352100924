import React from 'react'
import qs from 'query-string'
import Pagination from './Pagination'

const PaginationPaged = ({
  humanPageNumber = 1, hasNextPage = false, searchVariables = {}, pathPrefix = '', ...props
}) => (
  <Pagination
    {...props}
    previousPagePath={(
      humanPageNumber <= 1
        ? null
        : `${pathPrefix}?${qs.stringify({
          ...searchVariables,
          page: humanPageNumber - 1,
        })}`
    )}
    nextPagePath={(
      !hasNextPage
        ? null
        : `${pathPrefix}?${qs.stringify({
          ...searchVariables,
          page: humanPageNumber + 1,
        })}`
    )}
  />
)

export default PaginationPaged
