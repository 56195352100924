import React from 'react'
import { TextBlock, TextRow, RectShape } from 'react-placeholder/lib/placeholders'
import { Box, Flex } from '../ui'

const EventPreviewSkeleton = () => (
  <Flex
    color="secondary.50"
    flexDirection={['column', 'row']}
  >
    <Box flex={1}>
      <RectShape
        flex={1}
        color="currentColor"
        style={{ width: '100%', height: 170 }}
      />
    </Box>
    <Box
      flex={1}
      mt={[2, 0]}
      ml={[0, 2]}
    >
      <TextRow
        color="currentColor"
        style={{ margin: 0 }}
      />
      <TextRow
        color="currentColor"
        style={{ width: '80%' }}
      />
      <TextBlock
        rows={1}
        color="currentColor"
        style={{ width: '85%', marginTop: 16 }}
      />
    </Box>
  </Flex>
)

export default EventPreviewSkeleton
