import React, { memo } from 'react'
import { FlexCentered, Heading } from '../ui'
import EventList from './EventList'
import EventListSkeleton from './EventListSkeleton'

const EventsSearchResults = ({ loading, error, events }) => {
  if (loading || !events) {
    return <EventListSkeleton />
  }

  if (error || (events && events.length < 1)) {
    return (
      <FlexCentered>
        <Heading
          my={4}
          as="div"
        >
          Nincs találat.
        </Heading>
      </FlexCentered>
    )
  }

  return <EventList events={events} />
}

export default memo(EventsSearchResults)
