// eslint-disable-next-line
export const EVENTS_SEARCH_QUERY = `
query EventSearchQuery(
  $first: Int!, $offset: Int, $scope: EventScopeEnum, $search: String, $fromDate: String, $town: String, $categorySlugs: String
) {
  events(
    where: {
      search: $search
      fromDate: $fromDate
      town: $town
      categorySlugIn: $categorySlugs
      language: DEFAULT
      status: PUBLISH
      scope: $scope
      orderby: {
        order: ASC
        field: START_DATE
      }
      offsetPagination: {
        size: $first
        offset: $offset
      }
    }
  ) {
    pageInfo {
      offsetPagination {
        hasMore
      }
    }
    nodes {
      uri
      title
      excerpt
      startDate
      startTime
      endDate
      endTime
      allDay
      location {
        title: locationTitle
      }
      featuredImage {
        node {
          src: sourceUrl(size: NORMAL_POST)
          srcSet: sourceUrl(size: NORMAL_POST)
          mediaDetails {
            width
            height
          }
        }
      }
    }
  }
}
`
